<template>
  <b-modal
    id="edit-department"
    :title="mTitle"
    :ok-disabled="true"
    :cancel-disabled="true"
    @show="onShow"
    @hide="onHide"
  >
    <form class="r_form" v-if="form">
      <fieldset>
        <legend>Основная информация</legend>
        <div class="r_form_line">
          <div class="r_form_line_column" style="width: 100%">
            <label for="e_name">Название отдела<sup>*</sup></label>
            <input :disabled="loading" class="form-control" type="text" id="e_name" v-model="form.name" />
          </div>
        </div>
      </fieldset>
      <div class="r_form_submit">
        <button type="submit" @click.prevent="saveDepartment" class="btn btn-primary" :disabled="loading">
          {{ loading ? 'Выполняется...' : mOkTitle }}
        </button>
        <button
          class="btn btn-default"
          :disabled="loading"
          @click.prevent="$root.$emit('bv::hide::modal', 'edit-department')"
        >
          Отмена
        </button>
        <template v-if="!isNew">
          <button
            class="btn btn-link"
            style="margin-left: auto; color: #f30; text-decoration: none !important"
            :disabled="loading"
            @click.prevent="deleteDepartment"
          >
            &times;&nbsp;удалить отдел
          </button>
        </template>
      </div>
    </form>
  </b-modal>
</template>
<script>
  import api from '../../assets/js/api'
  import queryHelper from '../../assets/js/query'

  import forEach from 'lodash/forEach'
  import SelectDropdown from '../../components/SelectDropdown'

  export default {
    name: 'edit-department',
    components: { SelectDropdown },
    props: ['module'],
    computed: {
      mTitle() {
        return this.isNew ? `Добавление отдела` : `Редактирование отдела - ${this.form.name}`
      },
      mOkTitle() {
        return this.isNew ? 'Добавить отдел' : 'Сохранить отдел'
      },
      isNew() {
        return !this.form.id
      },
    },
    data() {
      return {
        form: {},
        loading: false,
        entity: 'departments',
      }
    },
    created() {},
    methods: {
      saveDepartment(e) {
        e.stopPropagation()
        e.preventDefault()

        this.loading = true

        let config = {
          entity: 'departments',
          params: queryHelper.getQuery(this.entity, this.$route.path, this.predefined),
          id: this.isNew ? null : this.form.id,
          data: this.form,
          storeSync: this.isNew,
          to: this.$route,
          from: this.$route,
        }
        console.log('start update', config)

        // адская адъ, не трогал
        api.base.update(config).then(
          result => {
            console.log('updated', result)

            this.$emit('updated', {
              result: result,
              isNew: this.isNew,
              form: this.form,
            })

            this.$nextTick(() => {
              this.$root.$emit('bv::hide::modal', 'edit-department')
            })
          },
          err => {
            console.log(err)
            this.loading = false
          }
        )
      },
      deleteDepartment(e) {
        if (confirm('Подтвердите удаление отдела')) {
          api.http.delete(`/departments/${this.form.id}`).then(
            response => {
              this.$root.$emit('bv::hide::modal', 'edit-department')
              window.location.reload()
            },
            err => {}
          )
        }
      },
      onShow() {
        this.$emit('show', this)
      },
      onHide() {
        this.$emit('hide', this)
      },
    },
  }
</script>
<style lang="scss">
  #edit-department .modal-dialog {
    max-width: 600px !important;
  }
  #edit-department .modal-footer {
    display: none !important; /* я не знаю как выключить кнопки и возможно ли это*/
  }
</style>
<style lang="scss" scoped>
  .r_form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    fieldset {
      display: block;
      width: 100%;
      font-family: inherit;
      border: 1px solid rgba(17, 17, 19, 0.1);
      border-radius: 4px;
      padding: 20px;
      margin-bottom: 16px;

      legend {
        font-size: 0.8rem;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(17, 17, 19, 0.5);
        padding: 0 1rem;
        margin-left: -1rem;
        line-height: 0;
        min-width: auto;
        width: auto;
      }
    }

    &_line {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      padding: 0 0 1rem 0;

      &_column {
        width: calc(100% / 3);
        padding: 0 10px 0 0;

        label {
          display: block;
          color: #333;
          margin: 0;
          padding: 0 0 2px 0;

          sup {
            color: #f30;
            font-weight: normal;
            font-size: 17px;
            line-height: 1;
            top: 0;
            margin-left: 2px;
          }
        }
        input {
          color: #333;
        }
      }
    }

    &_submit {
      padding: 1rem 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      .btn {
        margin: 0 0.5rem;
      }
    }
  }
</style>
